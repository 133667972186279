@import "@styles/media.scss";

.conocenos {
}

.TextCard {
  display: flex;

  :global(.MuiTypography-root) {
    margin-left: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 80%;
  margin: auto;
  margin-top: 2rem;
  :global(.MuiTypography-h3Bold) {
    display: block;
    margin-bottom: 0.5rem;
  }

  :global(.MuiCard-root) {
    max-width: 260px;
    padding: 1rem 0;
    justify-self: center;
  }

  :global(.MuiTypography-h6) {
    padding: 0 1rem;
  }
  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
    :global(.MuiCard-root) {
      min-height: 200px;
    }
  }
}
